import {
  put, takeLatest, call, all, select, takeEvery, delay,
} from 'redux-saga/effects';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import moment from 'moment';
import * as util from 'utils/utils';
import { isEntitled } from 'utils/auth.utils';
import { FEATURE_ENTITLEMENTS, ASSESS_RAW_IMAGERY_ENTITLEMENTS } from 'layout/entitleUser/EntitleUser.constants';
import { isEmpty, pick, sortBy } from 'lodash';
import * as api from './Adjuster.api';
import * as adjUtil from './Adjuster.utils';

import {
  FETCH_ADJUSTER_DETAILS,
  FETCH_ADJUSTER_DETAILS_GALLERY,
  FETCH_GALLERY_IMAGE_METADATA,
  FETCH_ADJUSTER_DETAILS_TAGS,
  FETCH_ADJUSTER_DETAILS_NOTES,
  FETCH_ROOF_FACETS,
  FETCH_ROOF_FACETS_AND_BASE_IMAGE,
  FETCH_FACET_BASE_IMAGE,
  // report related imports
  POST_REPORT,
  SHOW_REPORT,
  SAVE_ADJUSTER_DETAILS_NOTES,
  SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  SAVE_ADJUSTER_DETAILS_NOTES_FAILURE,
  DELETE_ADJUSTER_DETAILS_NOTES,
  DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  DELETE_ADJUSTER_DETAILS_NOTES_FAILURE,
  SAVE_ADJUSTER_DETAILS_TAGS,
  SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  SAVE_ADJUSTER_DETAILS_TAGS_FAILURE,
  DELETE_ADJUSTER_DETAILS_TAGS,
  DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS,
  DELETE_ADJUSTER_DETAILS_TAGS_FAILURE,
  SAVE_FACET_REVIEW_DECISION,
  SAVE_FACET_REVIEW_DECISION_SUCCESS,
  SAVE_FACET_REVIEW_DECISION_FAILURE,
  FETCH_IW_IMAGE_METADATA,
  FETCH_FACET_IMAGES,
  FETCH_FACET_METADATA,
  FETCH_RISK_SHOT,
  SAVE_ANOMALY_DECISION,
  SAVE_ANOMALY_DECISION_SUCCESS,
  SAVE_ANOMALY_DECISION_FAILURE,
  ADD_USER_ANOMALY,
  ADD_USER_ANOMALY_SUCCESS,
  ADD_USER_ANOMALY_FAILURE,
  DELETE_USER_ANOMALY,
  DELETE_USER_ANOMALY_SUCCESS,
  DELETE_USER_ANOMALY_FAILURE,
  SAVE_ROOF_DECISION,
  SAVE_ROOF_DECISION_SUCCESS,
  SAVE_ROOF_DECISION_FAILURE,
  UPDATE_ADJUSTER_DETAILS_NOTES,
  UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS,
  UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE,
  FETCH_ANOMALY_METADATA,
  SAVE_FACET_REPORT_INCLUSION,
  SAVE_FACET_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_REPORT_INCLUSION_FAILURE,
  FETCH_REPORT_INCLUDED_IMAGES,
  SAVE_IMAGE_REPORT_INCLUSION,
  SAVE_IMAGE_REPORT_INCLUSION_SUCCESS,
  SAVE_IMAGE_REPORT_INCLUSION_FAILURE,
  DELETE_IMAGE_REPORT_INCLUSION,
  DELETE_IMAGE_REPORT_INCLUSION_SUCCESS,
  DELETE_IMAGE_REPORT_INCLUSION_FAILURE,
  UPDATE_FACET_NOTES,
  UPDATE_FACET_NOTES_SUCCESS,
  UPDATE_FACET_NOTES_FAILURE,
  DELETE_FACET_NOTES,
  DELETE_FACET_NOTES_SUCCESS,
  DELETE_FACET_NOTES_FAILURE,
  SAVE_FACET_NOTES,
  SAVE_FACET_NOTES_SUCCESS,
  SAVE_FACET_NOTES_FAILURE,
  UPDATE_FACET_TEST_SQUARE_NOTES,
  UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE,
  DELETE_FACET_TEST_SQUARE_NOTES,
  DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  DELETE_FACET_TEST_SQUARE_NOTES_FAILURE,
  SAVE_FACET_TEST_SQUARE_NOTES,
  SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS,
  SAVE_FACET_TEST_SQUARE_NOTES_FAILURE,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE,
  FETCH_FACET_NOTES,
  FETCH_FACET_TEST_SQUARE_NOTES,
  FETCH_ROOF_FACETS_SUCCESS,
  SAVE_ROOF_NOTE,
  SAVE_ROOF_NOTE_SUCCESS,
  SAVE_ROOF_NOTE_FAILURE,
  UPDATE_ROOF_NOTE,
  UPDATE_ROOF_NOTE_SUCCESS,
  UPDATE_ROOF_NOTE_FAILURE,
  DELETE_ROOF_NOTE,
  DELETE_ROOF_NOTE_SUCCESS,
  DELETE_ROOF_NOTE_FAILURE,
  SAVE_FACET_NOTE_REPORT_INCLUSION,
  SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS,
  SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE,
  SAVE_IMAGE_NOTE_REPORT_INCLUSION,
  FETCH_SOFT_METAL_IMAGES,
  COMPLETE_REVIEW,
  QC_PANEL_APPROVE,
  QC_PANEL_REJECT,
  QC_PANEL_RETIEPOINT,
  QC_OPS_CHECKOUT_BY_REPORT_ID,
  QC_PANEL_RESCHEDULE,
  FETCH_QC_PANEL_DETAILS,
  DOWNLOAD_SKYDIO_MOSAIC_IMAGE,
  REJECT_QC_STRUCTURE_API,
  UNDO_QC_STRUCTURE_REJECTION_API,
  DISPLAY_GALLERY_ANNOTATIONS,
  SAVE_GALLERY_ANNOTATIONS,
  UPDATE_GALLERY_ANNOTATIONS,
  DELETE_GALLERY_ANNOTATIONS,
  TEST_SQUARE_STATUS_UPDATE,
  TEST_SQUARE_STATUS_UPDATE_SUCCESS,
  TEST_SQUARE_STATUS_UPDATE_FAILURE,
  TEST_SQUARE_LOCATION_UPDATE,
  FETCH_ASSESS_APP_SETTINGS,
  TOGGLE_ANOMALY_INCLUDE_IN_REPORT,
  EXPORT_ALL_IMAGES,
  EXPORT_REPORT_INCLUDED_IMAGES,
  POLL_EXPORT_IMAGES,
  DOWNLOAD_EXPORTED_IMAGES,
  MAX_EXPORT_IMAGES_POLL_TIME,
  EXPORT_IMAGES_POLL_DELAY_TIME,
  SAVE_ROTATION_FOR_GALLERY_IMAGES,
  FETCH_REPORT_VERSIONS,
  FETCH_FEATURE_PREFERENCE,
  SAVE_CONFIDENCE_FACTOR,
  SAVE_FACET_ANNOTATION,
  UPDATE_FACET_ANNOTATION,
  UPDATE_USER_DEFINED_ANOMALY_LOCATION,
  POLL_REPORT,
  REPORT_POLL_DELAY_TIME,
  MAX_REPORT_POLL_TIME,
  POLL_TIME_EXCEEDED,
  DISPLAY_FACET_ANNOTATIONS,
  DELETE_FACET_ANNOTATION,
  FETCH_FACET_IMAGE_METADATA,
  FETCH_ACCESSORIES_MASTER_LIST,
  FETCH_ACCESSORIES_SUMMARY,
  SHOW_REPORT_USING_REPORT_ID,
  FETCH_MULTISTRUCTURE_DECISION,
  UPDATE_STRUCTURE_VISIT_STATUS,
  FETCH_ROOF_NOTE,
  ROOF_DECISION,
  FETCH_ANOMALY_IMAGES,
  SAVE_QC_NOTE,
  FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES,
  SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION,
  UPDATE_FACET_ANNOTATION_INCL_IN_REPORT,
  UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT,
  FETCH_PRODUCT_COST,
  GENERATE_ROOF_REPORT,
  FETCH_REPORT_INCLUDED_SKYDIO_IMAGES,
  SAVE_SKYDIO_IMAGE_REPORT_INCLUSION,
  DELETE_SKYDIO_IMAGE_REPORT_INCLUSION,
  GET_ANOMALY_NOTES,
  ADD_ANOMALY_NOTE,
  UPDATE_ANOMALY_NOTE,
  INCLUDE_ANOMALY_NOTE,
  FETCH_FACET_IMAGE_ANOMALIES,
  FETCH_SHARED_USERS_LIST,
  CREATE_SHARE_LINK,
  REPORT_ATTEMPTS,
} from './Adjuster.constants';

import * as adjusterAction from './Adjuster.actions';

export const getMultiStructureDetails = (state) => ({
  selectedStructureId: state.adjusterReducer.selectedStructureId,
});

export function* getAdjusterDetailsSaga(action) {
  try {
    const response = yield call(api.loadAdjusterDetails, action.payload);
    const structures = sortBy(response.Structures, 'structureID');
    structures.forEach((structure, idx) => {
      const tempStructure = structure;
      tempStructure.structureName = idx === 0 ? 'Main Structure' : `Structure ${idx + 1}`;
      return tempStructure;
    });
    response.Structures = structures;
    yield put(adjusterAction.fetchAdjusterDetailsSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAdjusterDetailsActionFailure(util.parseServerError(error)));
  }
}

export function* toggleAnomalyIncludeInReportSaga({ payload }) {
  try {
    yield call(api.toggleAnomalyIncludeInReportApi, payload);
    yield put(adjusterAction.toggleAnomalyIncludeInReportSuccessAction({ includeInReport: payload.data.includeInReport, id: payload.anomalyID }));
  } catch (error) {
    yield put(adjusterAction.toggleAnomalyIncludeInReportFailureAction(util.parseServerError(error)));
  }
}

export function* getAdjusterDetailsGallerySaga(action) {
  try {
    const response = yield call(api.loadAdjusterDetailsGallery, action.payload);
    yield put(adjusterAction.fetchAdjusterDetailsGallerySuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAdjusterDetailsGalleryFailureAction(util.parseServerError(error)));
  }
}

/* save facet review decision */
export function* saveFacetReviewDecisionSaga({ payload }) {
  try {
    const response = yield call(api.postFacetDecision, payload);
    yield put(adjusterAction.saveFacetReviewDecisionSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.saveFacetReviewDecisionFailureAction(util.parseServerError(error)));
  }
}

/* fetch tags */
export function* fetchAdjusterDetailsTagsSaga(action) {
  try {
    const response = yield call(api.fetchImagesTags, action.payload);
    yield put(adjusterAction.fetchAdjusterDetailsTagsSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAdjusterDetailsTagsFailureAction(util.parseServerError(error)));
  }
}

/* fetch notes */
export function* fetchAdjusterDetailsNotesSaga(action) {
  try {
    const response = yield call(api.fetchImagesNotes, action.payload);
    yield put(adjusterAction.fetchAdjusterDetailsNotesSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAdjusterDetailsNotesFailureAction(util.parseServerError(error)));
  }
}

/* fetch facet notes */
export function* fetchFacetNotesSaga(action) {
  try {
    const response = yield call(api.fetchFacetNotes, action.payload);
    yield put(adjusterAction.fetchFacetNotesSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchFacetNotesFailureAction(util.parseServerError(error)));
  }
}

/* fetch facet test square notes */
export function* fetchFacetTestSquareNotesSaga({ payload }) {
  try {
    const response = yield call(api.fetchFacetTestSquareNotes, payload);
    yield put(adjusterAction.fetchFacetTestSquareNotesSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchFacetTestSquareNotesFailureAction(util.parseServerError(error)));
  }
}

/* save facet test square notes */
export function* saveFacetTestSquareNotesSaga({ payload }) {
  try {
    const response = yield call(api.postFacetTestSquareNote, payload);
    yield put(adjusterAction.saveFacetTestSquareNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetTestSquareNotesAction(payload));
  } catch (error) {
    yield put(adjusterAction.saveFacetTestSquareNotesFailureAction(util.parseServerError(error)));
  }
}

/* update facet test square notes */
export function* updateFacetTestSquareNotesSaga({ payload }) {
  try {
    const response = yield call(api.updateFacetTestSquareNote, payload);
    yield put(adjusterAction.updateFacetTestSquareNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetTestSquareNotesAction(payload));
  } catch (error) {
    yield put(adjusterAction.setTestSquareNoteReportInclusionAction({
      testSquareId: payload.testSquareId,
      noteId: payload.noteId,
      includeInReport: payload.noteInclusionBeforeUpdate,
    }));
    yield put(adjusterAction.updateFacetTestSquareNotesFailureAction(util.parseServerError(error)));
  }
}

/* delete facet test square notes */
export function* deleteFacetTestSquareNotesSaga({ payload }) {
  try {
    const response = yield call(api.deleteFacetTestSquareNote, payload);
    yield put(adjusterAction.deleteFacetTestSquareNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetTestSquareNotesAction(payload));
  } catch (error) {
    yield put(adjusterAction.deleteFacetTestSquareNotesFailureAction(util.parseServerError(error)));
  }
}

/* delete facet test square note report inclusion */
export function* saveFacetTestSquareNoteReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.saveFacetTestSquareNoteReportInclusion, payload);
    yield put(adjusterAction.saveFacetTestSquareNoteReportInclusionSuccessAction(response));
    yield put(adjusterAction.fetchFacetTestSquareNotesAction(payload));
  } catch (err) {
    yield put(adjusterAction.saveFacetTestSquareNoteReportInclusionFailureAction(util.parseServerError(err)));
  }
}

/* save tags */
export function* saveAdjusterDetailsTagsSaga({ payload }) {
  try {
    yield call(api.postImageTag, payload);
    yield put(adjusterAction.saveAdjusterDetailsTagsSuccessAction({ imageUrn: payload.image, imageTag: payload.data.tags[0] }));
  } catch (error) {
    yield put(adjusterAction.saveAdjusterDetailsTagsFailureAction(util.parseServerError(error)));
  }
}

/* delete tags */
export function* deleteAdjusterDetailsTagsSaga({ payload }) {
  try {
    yield call(api.deleteImageTag, payload);
    yield put(adjusterAction.deleteAdjusterDetailsTagsSuccessAction({ imageUrn: payload.image, imageTag: payload.data.tags[0] }));
  } catch (error) {
    yield put(adjusterAction.deleteAdjusterDetailsTagsFailureAction(util.parseServerError(error)));
  }
}

/* fetch product cost */
export function* fetchProductCostSaga(action) {
  try {
    const response = yield call(api.fetchProductCost, action.payload);
    yield put(adjusterAction.fetchProductCostSuccess(response));
  } catch (err) {
    yield put(adjusterAction.fetchProductCostFailure(util.parseServerError(err)));
  }
}

/* generate rood report */
export function* generateRoofReportSaga(action) {
  try {
    const response = yield call(api.generateRoofReport, action.payload);
    yield put(adjusterAction.generateRoofReportSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.generateRoofReportFailureAction(util.parseServerError(error)));
  }
}

/* save notes */
export function* saveAdjusterDetailsNotesSaga(action) {
  try {
    const response = yield call(api.postImagesNote, action.payload);
    yield put(adjusterAction.saveAdjusterDetailsNotesSuccessAction(response));
    yield put(adjusterAction.fetchAdjusterDetailsNotesAction(action.payload.orderId));
    if (get(action, 'payload.data.includeInReport', false)) {
      yield put(adjusterAction.saveImageReportInclusionAction({ orderId: action.payload.orderId, data: { referenceIds: [action.payload.image] } }));
    }
  } catch (error) {
    yield put(adjusterAction.saveAdjusterDetailsNotesFailureAction(util.parseServerError(error)));
  }
}
/* save Qc note */
export function* saveQcNoteSaga(action) {
  try {
    const response = yield call(api.postQcNote, action.payload);
    yield put(adjusterAction.saveQcNoteSuccessAction(response));
    const { selectedStructureId } = yield select(getMultiStructureDetails);
    yield put(adjusterAction.fetchQcPanelDetails({ orderId: action.payload.orderId, structureId: selectedStructureId }));
  } catch (error) {
    yield put(adjusterAction.saveQcNoteFailureAction(util.parseServerError(error)));
  }
}

/* save facet notes */
export function* saveFacetNotesSaga(action) {
  try {
    const response = yield call(api.postFacetNote, action.payload);
    yield put(adjusterAction.saveFacetNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetNotesAction(action.payload.orderId));
  } catch (error) {
    yield put(adjusterAction.saveFacetNotesFailureAction(util.parseServerError(error)));
  }
}

/* update notes */
export function* updateAdjusterDetailsNotesSaga(action) {
  try {
    const response = yield call(api.updateImagesNote, action.payload);
    yield put(adjusterAction.updateAdjusterDetailsNotesSuccessAction(response));
    yield put(adjusterAction.fetchAdjusterDetailsNotesAction(action.payload.orderId));
    if (get(action, 'payload.data.includeInReport', false)) {
      yield put(adjusterAction.saveImageReportInclusionAction({ orderId: action.payload.orderId, data: { referenceIds: [action.payload.image] } }));
    }
  } catch (error) {
    yield put(adjusterAction.setGalleryImageNoteReportInclusionAction({
      imageUrn: action.payload.image,
      noteId: action.payload.noteId,
      includeInReport: action.payload.noteInclusionBeforeUpdate,
    }));
    yield put(adjusterAction.updateAdjusterDetailsNotesFailureAction(util.parseServerError(error)));
  }
}

/* update facet notes */
export function* updateFacetNotesSaga(action) {
  try {
    const response = yield call(api.updateFacetNote, action.payload);
    yield put(adjusterAction.updateFacetNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetNotesAction(action.payload.orderId));
  } catch (error) {
    yield put(adjusterAction.setFacetNoteReportInclusionAction({
      facetId: action.payload.facet,
      noteId: action.payload.noteId,
      includeInReport: action.payload.noteInclusionBeforeUpdate,
    }));
    yield put(adjusterAction.updateFacetNotesFailureAction(util.parseServerError(error)));
  }
}

/* delete notes */
export function* deleteAdjusterDetailsNotesSaga(action) {
  try {
    const response = yield call(api.deleteImagesNote, action.payload);
    yield put(adjusterAction.deleteAdjusterDetailsNotesSuccessAction(response));
    yield put(adjusterAction.fetchAdjusterDetailsNotesAction(action.payload.orderId));
  } catch (error) {
    yield put(adjusterAction.deleteAdjusterDetailsNotesFailureAction(util.parseServerError(error)));
  }
}

/* delete facet notes */
export function* deleteFacetNotesSaga(action) {
  try {
    const response = yield call(api.deleteFacetNote, action.payload);
    yield put(adjusterAction.deleteFacetNotesSuccessAction(response));
    yield put(adjusterAction.fetchFacetNotesAction(action.payload.orderId));
  } catch (error) {
    yield put(adjusterAction.deleteFacetNotesFailureAction(util.parseServerError(error)));
  }
}

export function* getAdjusterDetailsFacetsSaga(action) {
  try {
    const response = yield call(api.loadRoofFacetsDetails, action.payload);
    const featureFlags = yield select((state) => state.entitleUserReducer.featureFlags);
    const debug = get(featureFlags, 'assessUiDebug', false);
    if (debug) {
      adjUtil.validateRoofFacetDetails(response);
    }
    const temp = adjUtil.tempRoofFacetMapper(response);
    if (action.type !== FETCH_ROOF_FACETS_AND_BASE_IMAGE) {
      yield put(adjusterAction.fetchRoofFacetsDetailsActionSuccess(temp));
    }
    return temp;
  } catch (error) {
    yield put(adjusterAction.fetchRoofFacetsDetailsActionFailure(util.parseError(error)));
    return {};
  }
}

export function* fetchFacetBaseImageSaga(action) {
  try {
    const baseImage = yield call(api.fetchFacetBaseImageApi, action.payload);
    const responseWithMetadata = yield call(api.fetchIwMetadata, baseImage.images[0].imageURN);
    const responseWithMetaDataAndOrientation = {
      ...responseWithMetadata,
      customMeta: get(baseImage, 'images[0].customMeta', {}),
    };
    yield put(adjusterAction.fetchFacetBaseImageSuccessAction(responseWithMetaDataAndOrientation));
    return responseWithMetaDataAndOrientation;
  } catch (error) {
    yield put(adjusterAction.fetchFacetBaseImageFailureAction(util.parseError(error)));
    return {};
  }
}

export function* getAdjusterDetailsFacetsAndBaseImageSaga(action) {
  const [baseImage, facetDetails] = yield all([
    call(fetchFacetBaseImageSaga, action),
    call(getAdjusterDetailsFacetsSaga, action),
  ]);

  if (!isEmpty(facetDetails) && !isEmpty(baseImage)) {
    yield put(adjusterAction.fetchRoofFacetsDetailsActionSuccess(adjUtil.checkForUnderHang(facetDetails, baseImage, action.payload.authtoken)));
  }
}

export function* postReportSaga(action) {
  try {
    const reportState = yield select((state) => state);
    const {
      adjusterReducer: {
        loading: { [POST_REPORT]: reportGeneratorFlag },
        reportGeneratorCount,
      },
    } = reportState;
    if (reportGeneratorCount === 0 && reportGeneratorFlag) {
      const response = yield call(api.postReport, action.payload, moment().utcOffset() * 60);
      const reportId = get(response, 'reportID', '');
      yield put(adjusterAction.postReportSuccessAction(reportId));
      yield put(adjusterAction.setPollStartTimeAction(reportId));
      yield put(adjusterAction.pollReport({ orderId: action.payload, reportId }));
    } else {
      yield put(adjusterAction.setReportOrderId(action.payload));
    }
  } catch (error) {
    yield put(adjusterAction.postReportActionFailure(util.parseServerError(error)));
  }
}

export function* pollReportGenerationSaga(action) {
  const { orderId, reportId } = action.payload;
  try {
    const reportsPollStartTime = yield select((state) => state.homeReducer.reportsPollStartTime);
    if (reportId) {
      const response = yield call(api.pollReportGeneration, orderId, reportId);
      const maxPollTime = new Date(reportsPollStartTime[reportId].getTime() + (MAX_REPORT_POLL_TIME * 60 * 1000));
      if (response.status === 202) {
        yield delay(REPORT_POLL_DELAY_TIME * 1000);
        if (new Date() <= maxPollTime) {
          yield put(adjusterAction.pollReport(action.payload));
        } else {
          throw new Error(POLL_TIME_EXCEEDED);
        }
      }
      if (response.status === 200) {
        yield put(adjusterAction.pollReportSuccessAction({ data: response.data, reportId }));
        yield put(adjusterAction.unsetPollStartTimeAction(reportId));
        const currentOrderId = yield select((state) => state.adjusterReducer.orderId);
        if (currentOrderId === orderId) {
          yield put(adjusterAction.fetchReportVersionsAction(orderId));
        }
      }
    }
  } catch (error) {
    yield put(adjusterAction.pollReportActionFailure({ error: util.parseServerError(error), reportId }));
    yield put(adjusterAction.unsetPollStartTimeAction(reportId));
  }
}

// Only success/error saga's which are dependent on report generation would enter this function.
export function* generateReportSaga() {
  try {
    yield put(adjusterAction.decrementGenerateReportDependents());
    const reportState = yield select((state) => state);
    const {
      adjusterReducer: { reportOrderId },
    } = reportState;
    yield call(postReportSaga, { payload: reportOrderId });
  } catch (error) {
    yield put(adjusterAction.decrementGenerateReportDependents());
    yield put(adjusterAction.postReportActionFailure(util.parseError(error)));
  }
}

// The actual saga calls which are dependent on report generation would enter this function.
export function* trackReportDependentsSaga(saga, action) {
  try {
    const reportState = yield select((state) => state);
    const {
      adjusterReducer: {
        loading: { [POST_REPORT]: reportGeneratorFlag },
      },
    } = reportState;
    if (!reportGeneratorFlag) {
      yield put(adjusterAction.incrementGenerateReportDependents());
    }
    yield call(saga, action);
  } catch (error) {
    const reportState = yield select((state) => state);
    const {
      adjusterReducer: {
        loading: { [POST_REPORT]: reportGeneratorFlag },
      },
    } = reportState;
    if (!reportGeneratorFlag) {
      yield put(adjusterAction.decrementGenerateReportDependents());
    }
  }
}

export function* fetchIwImageMetadataSaga(action) {
  try {
    const response = yield call(api.fetchIwMetadata, action.payload);
    yield put(adjusterAction.fetchIwImageMetadataSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchIwImageMetadataFailureAction(util.parseServerError(err)));
  }
}

export function* fetchGalleryImageMetadataSaga(action) {
  try {
    const response = yield call(api.fetchGalleryImageMetadata, action.payload);
    yield put(adjusterAction.fetchGalleryImageMetadataSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchGalleryImageMetadataFailureAction(util.parseServerError(err)));
  }
}

export function* fetchFacetImageMetaSaga(action) {
  try {
    const response = yield call(api.fetchIwMetadata, action.payload.imageURN);
    if (action.payload.isAnomalyImages) {
      yield put(adjusterAction.fetchAnomalyImageMetadataSuccessAction(response));
    } else {
      yield put(adjusterAction.fetchSoftMetalImageMetadataSuccessAction(response));
    }
  } catch (err) {
    yield put(adjusterAction.fetchFacetImageMetadataFailureAction(util.parseServerError(err)));
  }
}

export function* fetchRiskShotSaga(action) {
  try {
    const response = yield call(api.fetchRiskShot, action.payload);
    yield put(adjusterAction.fetchRiskShotSuccessAction({ structureId: action.payload.structureId, riskShot: response }));
  } catch (err) {
    yield put(adjusterAction.fetchRiskShotFailureAction(util.parseServerError(err)));
  }
}

export function* fetchAnomalyMetadataSaga(action) {
  try {
    const response = yield call(api.fetchIwMetadata, action.payload);
    yield put(adjusterAction.fetchAnomalyMetadataSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchAnomalyMetadataFailureAction(util.parseServerError(err)));
  }
}

export function* saveRoofDecisionSaga(action) {
  const roofId = get(action, 'payload.roofId', '');
  const roofDecisions = yield select((state) => state.adjusterReducer.roofDecision);
  const prevDecision = get(roofDecisions, 'roofId', undefined);
  const newDecision = action.payload.data.decision;
  try {
    // optimistic UI
    yield put(adjusterAction.setRoofDecisionAction({ roofId, decision: newDecision }));
    const response = yield call(api.postRoofDecision, action.payload);
    yield put(adjusterAction.saveRoofDecisionSuccessAction(response));
  } catch (err) {
    // on save error, rollback
    yield put(adjusterAction.setRoofDecisionAction({ roofId, decision: prevDecision }));
    yield put(adjusterAction.saveRoofDecisionFailureAction(util.parseServerError(err)));
  }
}

export function* fetchAnomalyImagesSaga(action) {
  try {
    const response = yield call(api.fetchFacetScanImages, action.payload);
    yield put(adjusterAction.fetchAnomalyImagesSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchAnomalyImagesFailureAction(util.parseServerError(err)));
  }
}

export function* fetchFacetImageMetadataSaga(action) {
  const response = yield call(api.fetchIwMetadata, action.payload.imageURN);
  yield put(adjusterAction.fetchFacetImagesSuccessAction({
    response: {
      data: [response], facetAliasName: action.payload.facetAliasName, isFacetScanImages: false, facetId: action.payload.facetId,
    },
  }));
}
export function* fetchFacetImagesSaga(action) {
  try {
    const facetMosaicImagesResponse = yield call(
      api.fetchFacetMosaicImages,
      action.payload,
    );
    if (facetMosaicImagesResponse.status === 200) {
      const images = get(facetMosaicImagesResponse, 'data.images', []);
      const imgUrns = images.map(
        ({ imageURN, meta: { facetAliasName } }) => ({ facetAliasName, imageURN, facetId: action.payload.facetIds[facetAliasName] }),
      );
      yield all(imgUrns.map((img) => put(adjusterAction.fetchFacetImageMetadataAction(img))));
      yield all(
        Object.keys(action.payload.facetIds)
          .filter((facetAliasName) => imgUrns.findIndex((img) => img.facetAliasName === facetAliasName) === -1)
          .map((facetAliasName) => put(adjusterAction.fetchFacetImagesSuccessAction({
            response: {
              data: [], facetAliasName, isFacetScanImages: true, facetId: action.payload.facetIds[facetAliasName],
            },
          }))),
      );
    }
  } catch (err) {
    yield put(adjusterAction.fetchFacetImagesFailureAction(util.parseServerError(err)));
  }
}

export function* saveAnomalyDecisionSaga(action) {
  const {
    payload: {
      orderId, anomalyData, orgDecision, isRawImageryWorkflow,
    },
  } = action;
  try {
    const saveAnomalyDecisionResponse = yield call(api.saveAnomalyDecision, orderId, anomalyData);
    yield put(
      adjusterAction.saveAnomalyDecisionSuccessAction(
        saveAnomalyDecisionResponse,
        anomalyData.anomalyId,
        anomalyData.facetId,
        isRawImageryWorkflow,
      ),
    );
  } catch (err) {
    yield put(adjusterAction.saveAnomalyDecisionFailureAction(util.parseServerError(err)));
    yield put(adjusterAction.setAnomalyDecisionAction({ ...anomalyData, decision: orgDecision, isRawImageryWorkflow }));
  }
}

export function* saveFacetReportInclusionSaga({ payload }) {
  const prevFacets = yield select((state) => state.adjusterReducer.facets);
  const newFacets = prevFacets.map((facet) => {
    if (facet.facetId !== payload.facetId) return facet;
    return ({ ...facet, includeInReport: payload.data.includeInReport });
  });
  try {
    const response = yield call(api.saveFacetReportInclusion, payload);
    yield put(adjusterAction.saveFacetReportInclusionSuccessAction(response));
    yield put(adjusterAction.setFacetReportInclusionAction(newFacets));
  } catch (err) {
    yield put(adjusterAction.saveFacetReportInclusionFailureAction(util.parseServerError(err)));
    yield put(adjusterAction.setFacetReportInclusionAction(prevFacets));
  }
}

export function* fetchImagesIncludedInReportSaga({ payload }) {
  try {
    const response = yield call(api.fetchImagesIncludedInReport, payload);
    yield put(adjusterAction.fetchImagesIncludedInReportSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchImagesIncludedInReportFailureAction(util.parseServerError(err)));
  }
}

export function* saveImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.saveImageReportInclusion, payload);
    yield put(adjusterAction.saveImageReportInclusionSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.saveImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* deleteImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.deleteImageReportInclusion, payload);
    yield put(adjusterAction.deleteImageReportInclusionSuccessAction({ response, referenceIds: payload.data.referenceIds }));
  } catch (err) {
    yield put(adjusterAction.deleteImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* showReportSaga(action) {
  const reportUrl = action.payload;
  try {
    const showReportResponse = yield call(api.showReport, reportUrl);
    const file = new Blob([showReportResponse], { type: 'application/pdf' });
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);
  } catch (err) {
    yield put(adjusterAction.showReportFailureAction(util.parseServerError(err)));
  }
}

export function* showReportUsingReportIdSaga(action) {
  try {
    const showReportResponse = yield call(api.showReportUsingReportId, action.payload);
    const file = new Blob([showReportResponse], { type: 'application/pdf' });
    const fileURL = window.URL.createObjectURL(file);
    window.open(fileURL);
  } catch (err) {
    yield put(adjusterAction.showReportFailureAction(util.parseServerError(err)));
  }
}

export function* addUserAnomalySaga(action) {
  const {
    orderId, anomalyData, decision, includeInReport, isRawImageryWorkflow, structureID, long, lat,
  } = action.payload;
  try {
    const reportState = yield select((state) => state);
    const {
      entitleUserReducer: { entitlements, featureFlags },
    } = reportState;

    const isFacetRawImageryWorkflowEnabled = get(featureFlags, ASSESS_RAW_IMAGERY_ENTITLEMENTS, false);
    let addUserAnomalyResponse = null;
    if (isFacetRawImageryWorkflowEnabled) {
      addUserAnomalyResponse = yield call(api.addUserAnomaly, orderId, anomalyData, structureID, long, lat);
    } else {
      addUserAnomalyResponse = yield call(api.oldAddUserAnomaly, orderId, anomalyData);
    }
    yield put(adjusterAction.addUserAnomalySuccessAction({ newAnomaly: addUserAnomalyResponse, isRawImageryWorkflow }));
    if (isRawImageryWorkflow) {
      yield put(adjusterAction.setRawImageSelectedAnomalyAction(addUserAnomalyResponse.anomalyId));
    } else {
      yield put(adjusterAction.setAnomalyIdAction(addUserAnomalyResponse.anomalyId));
    }
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.MANAGE_REPORTS) && !includeInReport && decision === 'UNDECIDED') {
      const selectedFacetObj = {
        orderId,
        facetId: anomalyData.facetId,
        data: {
          decision,
          facetAliasName: anomalyData.facetAliasName,
          roofId: anomalyData.roofId,
          includeInReport: true,
        },
      };
      yield put(adjusterAction.saveFacetReviewDecisionAction(selectedFacetObj));
    }
  } catch (err) {
    yield put(adjusterAction.addUserAnomalyFailureAction(util.parseServerError(err)));
  }
}

export function* deleteUserAnomalySaga(action) {
  const {
    orderId, facetId, anomalyId, isRawImageryWorkflow,
  } = action.payload;
  try {
    const response = yield call(api.deleteUserAnomaly, orderId, anomalyId);
    yield put(adjusterAction.deleteUserAnomalySuccessAction({
      orderId, facetId, anomalyId, isRawImageryWorkflow, mosaicAnomalyId: response.anomalyId,
    }));
  } catch (err) {
    yield put(adjusterAction.deleteUserAnomalyFailureAction(util.parseServerError(err)));
    yield put(adjusterAction.setAnomalyIdAction(anomalyId));
  }
}

export function* fetchRoofNoteSaga(action) {
  try {
    const reportState = yield select((state) => state);
    const {
      entitleUserReducer: { entitlements },
    } = reportState;
    if (isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_NOTES)
      || isEntitled(entitlements, FEATURE_ENTITLEMENTS.VIEW_ASSESS_ALL_ORGANIZATION_ORDERS)) {
      const payload = {
        orderId: action.payload.orderId,
        roofId: action.payload.roof.roofId,
      };
      const response = yield call(api.fetchRoofNoteApi, payload);
      yield put(adjusterAction.fetchRoofNoteSuccessAction({
        roofId: action.payload.roof.roofId,
        response,
      }));
    }
  } catch (err) {
    yield put(adjusterAction.fetchRoofNoteFailureAction(util.parseServerError(err)));
  }
}

export function* saveRoofNoteSaga(action) {
  try {
    const response = yield call(api.saveRoofNoteApi, action.payload);
    yield put(adjusterAction.saveRoofNoteSuccessAction({
      roofId: action.payload.roofId,
      response,
    }));
  } catch (err) {
    yield put(adjusterAction.saveRoofNoteFailureAction(util.parseServerError(err)));
  }
}

export function* updateRoofNoteSaga(action) {
  try {
    const response = yield call(api.updateRoofNoteApi, action.payload);
    yield put(adjusterAction.updateRoofNoteSuccessAction({
      roofId: action.payload.roofId,
      response,
    }));
  } catch (err) {
    yield put(adjusterAction.updateRoofNoteFailureAction(util.parseServerError(err)));
  }
}

export function* deleteRoofNoteSaga(action) {
  try {
    const response = yield call(api.deleteRoofNoteApi, action.payload);
    yield put(adjusterAction.deleteRoofNoteSuccessAction({
      roofId: action.payload.roofId,
      response,
    }));
  } catch (err) {
    yield put(adjusterAction.deleteRoofNoteFailureAction(util.parseServerError(err)));
  }
}

export function* saveImageNoteReportInclusionSaga(action) {
  try {
    const response = yield call(api.saveImageNoteReportInclusion, action.payload);
    yield put(adjusterAction.saveImageNoteReportInclusionSuccessAction(response));
    yield put(adjusterAction.fetchAdjusterDetailsNotesAction(action.payload.orderId));
    if (get(action, 'payload.data.includeInReport', false)) {
      yield put(adjusterAction.saveImageReportInclusionAction({ orderId: action.payload.orderId, data: { referenceIds: [action.payload.imageUrn] } }));
    }
  } catch (err) {
    yield put(adjusterAction.saveImageNoteReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* saveFacetNoteReportInclusionSaga(action) {
  try {
    const response = yield call(api.saveFacetNoteReportInclusion, action.payload);
    yield put(adjusterAction.saveFacetNoteReportInclusionSuccessAction(response));
    yield put(adjusterAction.fetchFacetNotesAction(action.payload.orderId));
  } catch (err) {
    yield put(adjusterAction.saveFacetNoteReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* fetchSoftMetalImagesSaga(action) {
  try {
    const response = yield call(api.fetchSoftMetalImages, action.payload);
    yield put(adjusterAction.fetchSoftMetalImagesSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchSoftMetalImagesFailureAction(util.parseServerError(err)));
  }
}

export function* completeReviewSaga(action) {
  try {
    yield call(api.completeReview, action.payload);
    yield put(push('/'));
  } catch (err) {
    yield put(adjusterAction.completeReviewFailureAction(util.parseServerError(err)));
  }
}

export function* postQcPanelApproveSaga(action) {
  try {
    const response = yield call(api.postQcPanelClaimApprove, action.payload);
    if (response.status === 204) {
      if (action.payload.isOpsUser === true) {
        try {
          yield call(api.checkInSimpleForQc, action.payload.taskStateIdForOpsPrime);
        } catch (err) {
          yield put(adjusterAction.qcOpsCheckinSimpleFailure(util.parseError(err)));
        }
      }
    }
    const { selectedStructureId } = yield select(getMultiStructureDetails);
    yield put(adjusterAction.fetchQcPanelDetails({ orderId: action.payload.orderId, structureId: selectedStructureId }));
    yield put(adjusterAction.postQcPanelClaimApproveSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.postQcPanelApproveFailureAction(util.parseServerError(err)));
  }
}

export function* postQcPanelRejectSaga(action) {
  try {
    const response = yield call(api.postQcPanelClaimReject, action.payload);
    if (response.status === 204) {
      if (action.payload.isOpsUser === true) {
        try {
          yield call(
            api.rejectSimpleNotesForQc,
            action.payload.taskStateIdForOpsPrime,
            action.payload.rejectReasonId,
            {},
            action.payload.assignPreferredUser,
          );
        } catch (err) {
          yield put(adjusterAction.qcOpsRejectSimpleNotesFailure(util.parseError(err)));
        }
      }
    }
    const { selectedStructureId } = yield select(getMultiStructureDetails);
    yield put(adjusterAction.fetchQcPanelDetails({ orderId: action.payload.orderId, structureId: selectedStructureId }));
    yield put(adjusterAction.postQcPanelClaimRejectSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.postQcPanelRejectFailureAction(util.parseServerError(err)));
  }
}

export function* postQcPanelRetiepointSaga(action) {
  try {
    const response = yield call(api.postQcPanelClaimRetiepoint, action.payload);
    if (response.status === 204) {
      if (action.payload.isOpsUser === true) {
        const assignQcOpsTiepointingToSelf = yield select((state) => state.adjusterReducer.assignQcOpsTiepointingToSelf);
        try {
          yield call(api.rejectSimpleNotesForQc, action.payload.taskStateIdForOpsPrime, action.payload.rejectReasonId, {}, assignQcOpsTiepointingToSelf);
        } catch (err) {
          yield put(adjusterAction.qcOpsRejectSimpleNotesFailure(util.parseError(err)));
        }
      }
    }
    const { selectedStructureId } = yield select(getMultiStructureDetails);
    yield put(adjusterAction.fetchQcPanelDetails({ orderId: action.payload.orderId, structureId: selectedStructureId }));
    yield put(adjusterAction.postQcPanelClaimRetiepointSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.postQcPanelRetiepointFailureAction(util.parseServerError(err)));
  }
}

export function* postCheckoutByreportIdSaga(action) {
  try {
    const response = yield call(api.checkoutByReportIdForQc, action.payload);
    yield put(adjusterAction.qcOpsCheckoutByReportIdSuccess(response));
  } catch (err) {
    yield put(adjusterAction.qcOpsCheckoutByReportIdFailure(util.parseServerError(err)));
  }
}

export function* postQcPanelRescheduleSaga(action) {
  try {
    const response = yield call(api.postQcPanelClaimReschedule, action.payload);
    const { selectedStructureId } = yield select(getMultiStructureDetails);
    yield put(adjusterAction.fetchQcPanelDetails({ orderId: action.payload.orderId, structureId: selectedStructureId }));
    yield put(adjusterAction.postQcPanelClaimRescheduleSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.postQcPanelRescheduleFailureAction(util.parseServerError(err)));
  }
}

export function* getQcPanelDetailsSaga(action) {
  try {
    const response = yield call(api.getQcPanelDetails, action.payload);
    yield put(adjusterAction.getQcPanelDetailsSuccess(response));
  } catch (err) {
    yield put(adjusterAction.getQcPanelDetailsSagaFailedAction(util.parseServerError(err)));
  }
}

export function* rejectQcStructureSaga(action) {
  try {
    const response = yield call(api.rejectQcStructureApi, action.payload);
    yield put(adjusterAction.rejectQcStructureSagaSuccessAction({
      response,
      orderId: action.payload.orderId,
      structureId: action.payload.structureId,
      roofSkippedState: action.payload.roofSkippedState,
    }));
  } catch (err) {
    yield put(adjusterAction.rejectQcStructureSagaFailureAction(util.parseServerError(err)));
  }
}

export function* undoQcStructureRejectionSaga(action) {
  try {
    const response = yield call(api.undoQcStructureRejectionApi, action.payload);
    yield put(adjusterAction.undoQcStructureRejectionSagaSuccessAction({
      response,
      orderId: action.payload.orderId,
      structureId: action.payload.structureId,
      roofSkippedState: action.payload.roofSkippedState,
    }));
  } catch (err) {
    yield put(adjusterAction.undoQcStructureRejectionSagaFailureAction(util.parseServerError(err)));
  }
}

export function* downloadSkydioMosaicImageSaga(action) {
  try {
    const response = yield call(api.downloadSkydioMosaicImage, action.payload);
    const skydioMosaicImageUrl = window.URL.createObjectURL(new Blob([response]));
    yield put(adjusterAction.downloadSkydioMosaicImageSuccessAction(skydioMosaicImageUrl));
  } catch (err) {
    yield put(adjusterAction.downloadSkydioMosaicImageFailedAction(util.parseServerError(err)));
  }
}

export function* displayGalleryAnnotationSaga(action) {
  try {
    const response = yield call(api.displayGalleryAnnotationApi, action.payload);
    yield put(adjusterAction.displayGalleryAnnotationSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.displayGalleryAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* saveGalleryAnnotationSaga(action) {
  try {
    const isImageIncluded = get(action, 'payload.isImageIncluded', false);
    const isAccessory = get(action, 'payload.isAccessory', false);
    const response = yield call(api.saveGalleryAnnotationApi, action.payload);
    const newAnnotations = get(response, 'images[0].annotations', []).map((annotation) => ({ ...annotation, geometries: [annotation.geometry] }));
    const imageId = get(response, 'images[0].imageID');
    const newAnnotationId = get(newAnnotations, '[0].annotationId');
    yield put(adjusterAction.saveGalleryAnnotationSuccessAction({ annotations: newAnnotations, imageId, annotationId: newAnnotationId }));
    if (isAccessory) {
      const orderId = get(action, 'payload.orderId', '');
      const selectedStructureId = get(action, 'payload.selectedStructureId', '');
      yield put(adjusterAction.fetchAccessorySummaryAction({ orderId, selectedStructureId }));
    }
    const isPointAnnotation = get(action, 'payload.annotationType', '') === 'point';
    if (!isImageIncluded && !isPointAnnotation) {
      // dispatching the action to include the image
      yield put(adjusterAction.saveImageReportInclusionAction({ orderId: action.payload.orderId, data: { referenceIds: [action.payload.imageId] } }));
    }
  } catch (err) {
    yield put(adjusterAction.saveGalleryAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* updateGalleryAnnotationInclInReportSaga(action) {
  try {
    yield call(api.updateGalleryAnnotationInclInReportApi, action.payload);
    yield put(adjusterAction.updateGalleryAnnotationInclInReportSuccessAction(action.payload));
  } catch (err) {
    yield put(adjusterAction.updateGalleryAnnotationInclInReportFailureAction(util.parseServerError(err)));
  }
}

export function* updateGalleryAnnotationSaga(action) {
  try {
    const response = yield call(api.updateGalleryAnnotationApi, action.payload);
    yield put(adjusterAction.updateGalleryAnnotationSuccessAction(response));
    const isAccessory = get(action, 'payload.isAccessory', false);
    const orderId = get(action, 'payload.orderId', '');
    if (isAccessory) {
      const selectedStructureId = get(action, 'payload.selectedStructureId', '');
      yield put(adjusterAction.fetchAccessorySummaryAction({ orderId, selectedStructureId }));
    } else {
      const imageId = get(action, 'payload.imageId', '');
      yield put(adjusterAction.displayGalleryAnnotationAction({ orderId, imageId }));
    }
  } catch (err) {
    yield put(adjusterAction.updateGalleryAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* deleteGalleryAnnotationSaga(action) {
  try {
    const response = yield call(api.deleteGalleryAnnotationApi, action.payload);
    yield put(adjusterAction.deleteGalleryAnnotationSuccessAction(response));
    yield put(adjusterAction.displayGalleryAnnotationAction(action.payload));
    const isAccessory = get(action, 'payload.isAccessory', false);
    if (isAccessory) {
      const orderId = get(action, 'payload.orderId', '');
      const selectedStructureId = get(action, 'payload.selectedStructureId', '');
      yield put(adjusterAction.fetchAccessorySummaryAction({ orderId, selectedStructureId }));
    }
    const deleteImageReportInclusion = get(action, 'payload.deleteImageReportInclusion', false);
    if (deleteImageReportInclusion) {
      const payload = {
        orderId: get(action, 'payload.orderId', ''),
        data: { referenceIds: [get(action, 'payload.imageId', '')] },
      };
      yield put(adjusterAction.deleteImageReportInclusionAction(payload));
    }
  } catch (err) {
    yield put(adjusterAction.deleteGalleryAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* testSquareStatusSaga(action) {
  try {
    yield call(api.testSquareStatusUpdateApi, action.payload);
    yield put(adjusterAction.testSquareStatusUpdateSuccessAction(action.payload));
  } catch (err) {
    yield put(adjusterAction.testSquareStatusUpdateFailureAction(util.parseServerError(err)));
  }
}

export function* exportAllImagesSaga(action) {
  try {
    const response = yield call(api.exportAllImagesApi, action.payload);
    const exportId = get(response, 'exportID', '');
    yield put(adjusterAction.setExportImageryPollStartTimeAction(exportId));
    yield put(adjusterAction.pollExportImagesAction({ orderId: action.payload, exportId }));
  } catch (error) {
    yield put(adjusterAction.exportAllImagesFailureAction(util.parseServerError(error)));
  }
}

export function* exportReportIncludedImagesSaga(action) {
  try {
    const response = yield call(api.exportReportIncludedImagesApi, action.payload);
    const exportId = get(response, 'exportID', '');
    yield put(adjusterAction.setExportImageryPollStartTimeAction(exportId));
    yield put(adjusterAction.pollExportImagesAction({ orderId: action.payload, exportId }));
  } catch (error) {
    yield put(adjusterAction.exportReportIncludedImagesFailureAction(util.parseServerError(error)));
  }
}

export function* pollExportImagesSaga(action) {
  const { orderId, exportId } = action.payload;
  try {
    const exportImagesPollStartTime = yield select((state) => state.homeReducer.exportImageryPollStartTime);
    if (exportId) {
      const response = yield call(api.pollExportImagesApi, orderId, exportId);
      const maxPollTime = new Date(exportImagesPollStartTime[exportId].getTime() + (MAX_EXPORT_IMAGES_POLL_TIME * 60 * 1000));
      if (response.status === 202) {
        yield delay(EXPORT_IMAGES_POLL_DELAY_TIME * 1000);
        if (new Date() <= maxPollTime) {
          yield put(adjusterAction.pollExportImagesAction(action.payload));
        } else {
          throw new Error(POLL_TIME_EXCEEDED);
        }
      }
      if (response.status === 200) {
        yield put(adjusterAction.pollExportImagesSuccessAction({ exportId }));
        yield put(adjusterAction.unsetExportImageryPollStartTimeAction(exportId));
        yield put(adjusterAction.downloadExportedImagesAction(action.payload));
      }
    }
  } catch (error) {
    yield put(adjusterAction.pollExportImagesFailureAction({ error: util.parseServerError(error), exportId }));
    yield put(adjusterAction.unsetExportImageryPollStartTimeAction(exportId));
  }
}

export function* downloadExportedImagesSaga(action) {
  const { orderId, exportId } = action.payload;
  try {
    const response = yield call(api.downloadExportedImagesApi, orderId, exportId);
    adjUtil.downloadZip(response);
    yield put(adjusterAction.downloadExportedImagesSuccessAction({ exportId }));
  } catch (error) {
    yield put(adjusterAction.downloadExportedImagesFailureAction({ error: util.parseServerError(error), exportId }));
  }
}

export function* fetchAssessAppSettingsSaga() {
  try {
    const response = yield call(api.fetchAssessAppSettingsApi);
    yield put(adjusterAction.fetchAssessAppSettingsSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAssessAppSettingsFailureAction(util.parseServerError(error)));
  }
}

export function* fetchReportVersionsSaga(action) {
  try {
    const response = yield call(api.fetchReportVersionsApi, action.payload);
    const reportList = get(response, 'reports');
    yield put(adjusterAction.fetchReportVersionsSuccessAction(reportList));
  } catch (err) {
    yield put(adjusterAction.fetchReportVersionsFailureAction(util.parseServerError(err)));
  }
}

export function* updateTestSquareLocationSaga(action) {
  try {
    const response = yield call(api.updateTestSquareLocationApi, action.payload);
    yield put(adjusterAction.testSquareLocationUpdateSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.testSquareLocationUpdateFailureAction(util.parseServerError(err)));
  }
}

export function* saveRotationforGalleryImagesSaga(action) {
  try {
    const response = yield call(api.saveRotationforGalleryImages, action.payload);
    yield put(adjusterAction.saveRotationforGalleryImagesSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.saveRotationforGalleryImagesFailureAction(util.parseServerError(error)));
  }
}

export function* fetchFeaturePreferenceSaga() {
  try {
    const response = yield call(api.fetchFeaturePreferenceApi);
    const featurePreferences = get(response, 'optedOut');
    yield put(adjusterAction.fetchFeaturePreferencesSuccessAction(featurePreferences));
  } catch (error) {
    yield put(adjusterAction.fetchFeaturePreferencesFailureAction(util.parseServerError(error)));
  }
}

export function* saveConfidenceFactorSaga(action) {
  const orderId = get(action, 'payload.orderId');
  const confidenceFactor = get(action, 'payload.confidenceFactor');
  try {
    yield call(api.saveConfidenceFactorApi, orderId, confidenceFactor);
    yield put(adjusterAction.saveConfidenceFactorSuccessAction(confidenceFactor));
  } catch (error) {
    yield put(adjusterAction.saveConfidenceFactorFailureAction(confidenceFactor));
  }
}

export function* saveAnnotationForFacetSaga(action) {
  try {
    const orderId = get(action, 'payload.orderId', '');
    const facetId = get(action, 'payload.facetId', '');
    const data = get(action, 'payload.data', {});
    const response = yield call(api.saveAnnotationForFacet, orderId, facetId, data);
    yield put(adjusterAction.saveFacetAnnotationSuccessAction(response.data));
  } catch (error) {
    yield put(adjusterAction.saveFacetAnnotationFailureAction(util.parseServerError(error)));
  }
}

export function* updateFacetAnnotationSaga(action) {
  try {
    const response = yield call(api.updateFacetAnnotationApi, action.payload);
    yield put(adjusterAction.updateFacetAnnotationSuccessAction(response));
    yield put(adjusterAction.displayFacetAnnotationAction({ ...pick(action.payload, ['facetId', 'orderId']) }));
  } catch (err) {
    yield put(adjusterAction.updateFacetAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* updateFacetAnnotationInclInReportSaga(action) {
  try {
    yield call(api.updateFacetAnnotationInclInReportApi, action.payload);
    yield put(adjusterAction.updateFacetAnnotationInclInReportSuccessAction(action.payload));
  } catch (err) {
    yield put(adjusterAction.updateFacetAnnotationInclInReportFailureAction(util.parseServerError(err)));
  }
}

export function* updateUserDefinedAnomalyLocationSaga(action) {
  try {
    const data = get(action, 'payload.data', {});
    const orderId = get(action, 'payload.orderId', '');
    const anomalyId = get(action, 'payload.anomalyId', '');
    const response = yield call(api.updateUserAnomalyLocationApi, data, orderId, anomalyId);

    yield put(adjusterAction.updateUserAnomalyLocationSuccessAction(response));
    yield put(adjusterAction.fetchAnomalyMetadataAction(response.imageUrn));
  } catch (error) {
    yield put(adjusterAction.updateUserAnomalyLocationFailureAction(util.parseServerError(error)));
  }
}

export function* displayFacetAnnotationSaga(action) {
  try {
    const response = yield call(api.displayFacetAnnotationApi, action.payload);
    const facets = yield select((state) => state.adjusterReducer.facets);
    const currentFacet = facets.find((facet) => facet.facetId === action.payload.facetId);
    const planeConstants = get(currentFacet, 'properties.plane', undefined);
    const featureFlags = yield select((state) => state.entitleUserReducer.featureFlags);
    const debug = get(featureFlags, 'assessUiDebug', false);
    if (debug) {
      adjUtil.validateFacetAnnotations(action.payload.facetId, response, planeConstants);
    }
    yield put(adjusterAction.displayFacetAnnotationSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.displayFacetAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* deleteFacetAnnotationSaga(action) {
  try {
    yield call(api.deleteFecetAnnotationApi, action.payload);
    yield put(adjusterAction.deleteFacetAnnotationSuccessAction(action.payload));
  } catch (err) {
    yield put(adjusterAction.deleteFacetAnnotationFailureAction(util.parseServerError(err)));
  }
}

export function* getAnomalyNotesSaga(action) {
  try {
    const response = yield call(api.getAnomalyNotesApi, action.payload);
    yield put(adjusterAction.getAnomalyNotesSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.getAnomalyNotesFailureAction(util.parseServerError(err)));
  }
}

export function* addAnomalyNoteSaga(action) {
  try {
    const response = yield call(api.addAnomalyNoteApi, action.payload);
    yield put(adjusterAction.addAnomalyNoteSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.addAnomalyNoteFailureAction(util.parseServerError(err)));
  }
}

export function* updateAnomalyNoteSaga(action) {
  try {
    let response = {};
    if (isEmpty(get(action, 'payload.data.note', ''))) {
      response = yield call(api.deleteAnomalyNoteApi, action.payload);
      yield put(adjusterAction.includeAnomalyNoteAction({ ...action.payload, includeInReport: false }));
    } else {
      response = yield call(api.updateAnomalyNoteApi, action.payload);
    }
    yield put(adjusterAction.updateAnomalyNoteSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.updateAnomalyNoteFailureAction(util.parseServerError(err)));
  }
}

export function* includeAnomalyNoteSaga(action) {
  try {
    const response = yield call(api.includeAnomalyNoteApi, action.payload);
    yield put(adjusterAction.includeAnomalyNoteSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.includeAnomalyNoteFailureAction(util.parseServerError(err)));
  }
}

export function* fetchAccessoryMasterListSaga(action) {
  try {
    const response = yield call(api.getAccessoryMasterList, action.payload);
    yield put(adjusterAction.fetchAccessoryMasterListSuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAccessoryMasterListFailureAction(util.parseServerError(error)));
  }
}

export function* fetchAccessorySummarySaga(action) {
  try {
    const response = yield call(api.getAccessorySummary, action.payload);
    yield put(adjusterAction.fetchAccessorySummarySuccessAction(response));
  } catch (error) {
    yield put(adjusterAction.fetchAccessorySummaryFailureAction(util.parseServerError(error)));
  }
}

export function* fetchMultiStructureDecisionSaga(action) {
  try {
    const response = yield call(api.getMultiStructureDecisions, action.payload);
    const structureRoofMapping = {};
    const decisions = {};
    const structures = get(response, 'structures', []);
    structures.forEach(({ structureID, roofID, decision }) => {
      decisions[roofID] = isEmpty(decision) ? 'Undecided' : ROOF_DECISION[decision];
      structureRoofMapping[structureID] = roofID;
    });
    yield all(
      structures.map(({ roofID }) => put(adjusterAction.fetchRoofNoteAction({ roof: { roofId: roofID }, orderId: action.payload.orderId }))),
    );
    yield all(
      structures.map(({ structureID }) => put(adjusterAction.fetchRiskShotAction({ orderId: action.payload.orderId, structureId: structureID }))),
    );

    yield put(adjusterAction.fetchMultiStructureDecisionsSuccessAction({ decisions, structureRoofMapping }));
  } catch (error) {
    yield put(adjusterAction.fetchMultiStructureDecisionsFailureAction(util.parseServerError(error)));
  }
}

export function* updateStructureVisitStatusSaga(action) {
  try {
    const response = yield call(api.updateStructureVisitStatusApi, action.payload);
    // eslint-disable-next-line max-len
    yield put(adjusterAction.updateStructureVisitStatusSuccessAction({ response, structureID: action.payload.structureID, userRole: action.payload.userRole }));
  } catch (err) {
    yield put(adjusterAction.updateStructureVisitStatusFailureAction(util.parseServerError(err)));
  }
}

export function* fetchSoftMetalImagesIncludedInReportSaga({ payload }) {
  try {
    const response = yield call(api.fetchSoftMetalImagesIncludedInReport, payload);
    yield put(adjusterAction.fetchSoftMetalImagesIncludedInReportSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchSoftMetalImagesIncludedInReportFailureAction(util.parseServerError(err)));
  }
}

export function* saveSoftMetalImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.saveSoftMetalImageReportInclusion, payload);
    yield put(adjusterAction.saveSoftMetalImageReportInclusionSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.saveSoftMetalImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* deleteSoftMetalImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.deleteSoftMetalImageReportInclusion, payload);
    yield put(adjusterAction.deleteSoftMetalImageReportInclusionSuccessAction({ response, referenceIds: payload.data.referenceIds }));
  } catch (err) {
    yield put(adjusterAction.deleteSoftMetalImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* fetchSkydioImagesIncludedInReportSaga({ payload }) {
  try {
    const response = yield call(api.fetchSkydioImagesIncludedInReport, payload);
    yield put(adjusterAction.fetchSkydioImagesIncludedInReportSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchSkydioImagesIncludedInReportFailureAction(util.parseServerError(err)));
  }
}

export function* saveSkydioImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.saveSkydioImageReportInclusion, payload);
    yield put(adjusterAction.saveSkydioImageReportInclusionSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.saveSkydioImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* deleteSkydioImageReportInclusionSaga({ payload }) {
  try {
    const response = yield call(api.deleteSkydioImageReportInclusion, payload);
    yield put(adjusterAction.deleteSkydioImageReportInclusionSuccessAction({ response, referenceIds: payload.data.referenceIds }));
  } catch (err) {
    yield put(adjusterAction.deleteSkydioImageReportInclusionFailureAction(util.parseServerError(err)));
  }
}

export function* fetchFacetImageAnomaliesSaga({ payload }) {
  try {
    const response = yield call(api.fetchFacetImageAnomalies, payload);
    yield put(adjusterAction.fetchImageAnomaliesSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchImageAnomaliesFailureAction(util.parseServerError(err)));
  }
}

export function* fetchSharedUsersListSaga({ payload }) {
  try {
    const response = yield call(api.fetchSharedUsersList, payload);
    yield put(adjusterAction.fetchSharedUsersListSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.fetchSharedUsersListFailureAction(util.parseServerError(err)));
  }
}

export function* createShareLinkSaga({ payload }) {
  try {
    const response = yield call(api.createShareLink, payload);
    if (response.userList.length) {
      for (let i = 0; i < response.userList.length; i += 1) {
        const userSharedDetails = response.userList[i];
        yield call(api.publishDomainEvent, {
          eventName: 'shareable-link',
          eventData: {
            OrderId: payload.orderID,
            ReceiverEmail: userSharedDetails.sharedUserID,
            SenderNotes: payload.notes,
            SharedLink: userSharedDetails.sharedUrl,
            SenderId: payload.evUserID,
          },
        });
      }
    }
    yield put(adjusterAction.createShareLinkSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.createShareLinkFailureAction(util.parseServerError(err)));
  }
}

export function* getReportAttempts({ payload }) {
  try {
    const response = yield call(api.getReportAttempts, payload);
    yield put(adjusterAction.getReportAttemptsSuccessAction(response));
  } catch (err) {
    yield put(adjusterAction.getReportAttemptsFailureAction(util.parseServerError(err)));
  }
}

export default function* adjusterSaga() {
  yield takeLatest(QC_PANEL_APPROVE, postQcPanelApproveSaga);
  yield takeLatest(QC_PANEL_REJECT, postQcPanelRejectSaga);
  yield takeLatest(QC_PANEL_RETIEPOINT, postQcPanelRetiepointSaga);
  yield takeLatest(QC_OPS_CHECKOUT_BY_REPORT_ID, postCheckoutByreportIdSaga);
  yield takeLatest(QC_PANEL_RESCHEDULE, postQcPanelRescheduleSaga);
  yield takeLatest(FETCH_QC_PANEL_DETAILS, getQcPanelDetailsSaga);
  yield takeLatest(DOWNLOAD_SKYDIO_MOSAIC_IMAGE, downloadSkydioMosaicImageSaga);
  yield takeLatest(REJECT_QC_STRUCTURE_API, rejectQcStructureSaga);
  yield takeLatest(UNDO_QC_STRUCTURE_REJECTION_API, undoQcStructureRejectionSaga);
  yield takeLatest(COMPLETE_REVIEW, completeReviewSaga);
  yield takeLatest(FETCH_ADJUSTER_DETAILS, getAdjusterDetailsSaga);
  yield takeLatest(FETCH_ADJUSTER_DETAILS_GALLERY, getAdjusterDetailsGallerySaga);
  yield takeLatest(FETCH_GALLERY_IMAGE_METADATA, fetchGalleryImageMetadataSaga);
  yield takeLatest(FETCH_FACET_IMAGE_METADATA, fetchFacetImageMetaSaga);
  yield takeLatest(FETCH_ROOF_FACETS, getAdjusterDetailsFacetsSaga);
  yield takeLatest(FETCH_ROOF_FACETS_AND_BASE_IMAGE, getAdjusterDetailsFacetsAndBaseImageSaga);
  yield takeLatest(FETCH_ADJUSTER_DETAILS_TAGS, fetchAdjusterDetailsTagsSaga);
  yield takeLatest(FETCH_ADJUSTER_DETAILS_NOTES, fetchAdjusterDetailsNotesSaga);
  yield takeLatest(FETCH_FACET_NOTES, fetchFacetNotesSaga);
  yield takeLatest(FETCH_FACET_TEST_SQUARE_NOTES, fetchFacetTestSquareNotesSaga);
  yield takeLatest(FETCH_FACET_BASE_IMAGE, fetchFacetBaseImageSaga);
  yield takeLatest(FETCH_REPORT_INCLUDED_IMAGES, fetchImagesIncludedInReportSaga);
  yield takeLatest(FETCH_SOFT_METAL_IMAGES, fetchSoftMetalImagesSaga);
  // Report dependent saga's
  // Save facet test square notes
  yield takeEvery(SAVE_FACET_TEST_SQUARE_NOTES, trackReportDependentsSaga, saveFacetTestSquareNotesSaga);
  yield takeLatest(SAVE_FACET_TEST_SQUARE_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_TEST_SQUARE_NOTES_FAILURE, generateReportSaga);
  // test square status
  yield takeEvery(TEST_SQUARE_STATUS_UPDATE, trackReportDependentsSaga, testSquareStatusSaga);
  yield takeLatest(TEST_SQUARE_STATUS_UPDATE_SUCCESS, generateReportSaga);
  yield takeLatest(TEST_SQUARE_STATUS_UPDATE_FAILURE, generateReportSaga);
  // Update facet test square notes
  yield takeEvery(UPDATE_FACET_TEST_SQUARE_NOTES, trackReportDependentsSaga, updateFacetTestSquareNotesSaga);
  yield takeLatest(UPDATE_FACET_TEST_SQUARE_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(UPDATE_FACET_TEST_SQUARE_NOTES_FAILURE, generateReportSaga);
  // Delete facet notes
  yield takeEvery(DELETE_FACET_TEST_SQUARE_NOTES, trackReportDependentsSaga, deleteFacetTestSquareNotesSaga);
  yield takeLatest(DELETE_FACET_TEST_SQUARE_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_FACET_TEST_SQUARE_NOTES_FAILURE, generateReportSaga);
  // include facet note in report
  yield takeEvery(
    SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION,
    trackReportDependentsSaga,
    saveFacetTestSquareNoteReportInclusionSaga,
  );
  yield takeLatest(SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_TEST_SQUARE_NOTE_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // include facet note in report
  yield takeEvery(
    TOGGLE_ANOMALY_INCLUDE_IN_REPORT,
    trackReportDependentsSaga,
    toggleAnomalyIncludeInReportSaga,
  );
  yield takeLatest(TOGGLE_ANOMALY_INCLUDE_IN_REPORT, generateReportSaga);
  yield takeLatest(TOGGLE_ANOMALY_INCLUDE_IN_REPORT, generateReportSaga);
  // Save notes
  yield takeEvery(
    SAVE_ADJUSTER_DETAILS_NOTES,
    trackReportDependentsSaga,
    saveAdjusterDetailsNotesSaga,
  );
  yield takeLatest(SAVE_ADJUSTER_DETAILS_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_ADJUSTER_DETAILS_NOTES_FAILURE, generateReportSaga);

  // Fetch product cost
  yield takeEvery(FETCH_PRODUCT_COST, fetchProductCostSaga);

  // Generate Roof Report
  yield takeEvery(GENERATE_ROOF_REPORT, generateRoofReportSaga);

  // Save Qc note
  yield takeEvery(SAVE_QC_NOTE, saveQcNoteSaga);

  // Save facet notes
  yield takeEvery(SAVE_FACET_NOTES, trackReportDependentsSaga, saveFacetNotesSaga);
  yield takeLatest(SAVE_FACET_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_NOTES_FAILURE, generateReportSaga);
  // Update notes
  yield takeEvery(
    UPDATE_ADJUSTER_DETAILS_NOTES,
    trackReportDependentsSaga,
    updateAdjusterDetailsNotesSaga,
  );
  yield takeLatest(UPDATE_ADJUSTER_DETAILS_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(UPDATE_ADJUSTER_DETAILS_NOTES_FAILURE, generateReportSaga);
  // Update facet notes
  yield takeEvery(UPDATE_FACET_NOTES, trackReportDependentsSaga, updateFacetNotesSaga);
  yield takeLatest(UPDATE_FACET_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(UPDATE_FACET_NOTES_FAILURE, generateReportSaga);
  // Delete notes
  yield takeEvery(
    DELETE_ADJUSTER_DETAILS_NOTES,
    trackReportDependentsSaga,
    deleteAdjusterDetailsNotesSaga,
  );
  yield takeLatest(DELETE_ADJUSTER_DETAILS_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_ADJUSTER_DETAILS_NOTES_FAILURE, generateReportSaga);
  // Delete facet notes
  yield takeEvery(DELETE_FACET_NOTES, trackReportDependentsSaga, deleteFacetNotesSaga);
  yield takeLatest(DELETE_FACET_NOTES_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_FACET_NOTES_FAILURE, generateReportSaga);
  // Save tags
  yield takeEvery(
    SAVE_ADJUSTER_DETAILS_TAGS,
    trackReportDependentsSaga,
    saveAdjusterDetailsTagsSaga,
  );
  yield takeLatest(SAVE_ADJUSTER_DETAILS_TAGS_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_ADJUSTER_DETAILS_TAGS_FAILURE, generateReportSaga);
  // Delete tags
  yield takeEvery(
    DELETE_ADJUSTER_DETAILS_TAGS,
    trackReportDependentsSaga,
    deleteAdjusterDetailsTagsSaga,
  );
  yield takeLatest(DELETE_ADJUSTER_DETAILS_TAGS_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_ADJUSTER_DETAILS_TAGS_FAILURE, generateReportSaga);
  // Save Facet Review Decision
  yield takeEvery(
    SAVE_FACET_REVIEW_DECISION,
    trackReportDependentsSaga,
    saveFacetReviewDecisionSaga,
  );
  yield takeLatest(SAVE_FACET_REVIEW_DECISION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_REVIEW_DECISION_FAILURE, generateReportSaga);
  // Save Facet Review Decision
  yield takeEvery(
    SAVE_FACET_REPORT_INCLUSION,
    trackReportDependentsSaga,
    saveFacetReportInclusionSaga,
  );
  yield takeLatest(SAVE_FACET_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // Save Image Report Inclusion
  yield takeEvery(
    SAVE_IMAGE_REPORT_INCLUSION,
    trackReportDependentsSaga,
    saveImageReportInclusionSaga,
  );
  yield takeLatest(SAVE_IMAGE_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_IMAGE_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // Delete Image Report Inclusion
  yield takeEvery(
    DELETE_IMAGE_REPORT_INCLUSION,
    trackReportDependentsSaga,
    deleteImageReportInclusionSaga,
  );
  yield takeLatest(DELETE_IMAGE_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_IMAGE_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // Generate report
  yield takeLatest(POST_REPORT, postReportSaga);
  // Fetch iw metadata
  yield takeLatest(FETCH_IW_IMAGE_METADATA, fetchIwImageMetadataSaga);
  yield takeEvery(FETCH_FACET_IMAGES, fetchFacetImagesSaga);
  yield takeEvery(FETCH_FACET_METADATA, fetchFacetImageMetadataSaga);
  yield takeEvery(FETCH_RISK_SHOT, fetchRiskShotSaga);
  yield takeLatest(FETCH_ANOMALY_METADATA, fetchAnomalyMetadataSaga);
  // Save anomaly decision
  yield takeEvery(
    SAVE_ANOMALY_DECISION,
    trackReportDependentsSaga,
    saveAnomalyDecisionSaga,
  );
  yield takeLatest(SAVE_ANOMALY_DECISION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_ANOMALY_DECISION_FAILURE, generateReportSaga);
  // Add user anomaly
  yield takeEvery(
    ADD_USER_ANOMALY,
    trackReportDependentsSaga,
    addUserAnomalySaga,
  );
  yield takeLatest(ADD_USER_ANOMALY_SUCCESS, generateReportSaga);
  yield takeLatest(ADD_USER_ANOMALY_FAILURE, generateReportSaga);
  // Delete user anomaly
  yield takeEvery(
    DELETE_USER_ANOMALY,
    trackReportDependentsSaga,
    deleteUserAnomalySaga,
  );
  yield takeLatest(DELETE_USER_ANOMALY_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_USER_ANOMALY_FAILURE, generateReportSaga);
  // Save roof decision
  yield takeEvery(
    SAVE_ROOF_DECISION,
    trackReportDependentsSaga,
    saveRoofDecisionSaga,
  );
  yield takeLatest(SAVE_ROOF_DECISION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_ROOF_DECISION_FAILURE, generateReportSaga);
  // roof note
  yield takeEvery(FETCH_ROOF_NOTE, fetchRoofNoteSaga);
  yield takeLatest(FETCH_ROOF_FACETS_SUCCESS, fetchRoofNoteSaga);
  // Save roof note
  yield takeEvery(
    SAVE_ROOF_NOTE,
    trackReportDependentsSaga,
    saveRoofNoteSaga,
  );
  yield takeLatest(SAVE_ROOF_NOTE_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_ROOF_NOTE_FAILURE, generateReportSaga);
  // Save roof note
  yield takeEvery(
    UPDATE_ROOF_NOTE,
    trackReportDependentsSaga,
    updateRoofNoteSaga,
  );
  yield takeLatest(UPDATE_ROOF_NOTE_SUCCESS, generateReportSaga);
  yield takeLatest(UPDATE_ROOF_NOTE_FAILURE, generateReportSaga);
  // Delete roof note
  yield takeEvery(
    DELETE_ROOF_NOTE,
    trackReportDependentsSaga,
    deleteRoofNoteSaga,
  );
  yield takeLatest(DELETE_ROOF_NOTE_SUCCESS, generateReportSaga);
  yield takeLatest(DELETE_ROOF_NOTE_FAILURE, generateReportSaga);
  // Poll for generated report
  yield takeEvery(POLL_REPORT, pollReportGenerationSaga);
  // Show report
  yield takeEvery(SHOW_REPORT, showReportSaga);
  yield takeEvery(SHOW_REPORT_USING_REPORT_ID, showReportUsingReportIdSaga);
  // include image note in report
  yield takeEvery(
    SAVE_IMAGE_NOTE_REPORT_INCLUSION,
    trackReportDependentsSaga,
    saveImageNoteReportInclusionSaga,
  );
  yield takeLatest(SAVE_IMAGE_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_IMAGE_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // include facet note in report
  yield takeEvery(
    SAVE_FACET_NOTE_REPORT_INCLUSION,
    trackReportDependentsSaga,
    saveFacetNoteReportInclusionSaga,
  );
  yield takeLatest(SAVE_FACET_NOTE_REPORT_INCLUSION_SUCCESS, generateReportSaga);
  yield takeLatest(SAVE_FACET_NOTE_REPORT_INCLUSION_FAILURE, generateReportSaga);
  // display gallery annotations
  yield takeLatest(DISPLAY_GALLERY_ANNOTATIONS, displayGalleryAnnotationSaga);
  // save gallery annotation
  yield takeLatest(SAVE_GALLERY_ANNOTATIONS, saveGalleryAnnotationSaga);
  // update gallery annotation
  yield takeLatest(UPDATE_GALLERY_ANNOTATIONS, updateGalleryAnnotationSaga);
  yield takeLatest(UPDATE_GALLERY_ANNOTATION_INCL_IN_REPORT, updateGalleryAnnotationInclInReportSaga);
  // delete gallery annotation
  yield takeLatest(DELETE_GALLERY_ANNOTATIONS, deleteGalleryAnnotationSaga);
  // export all images
  yield takeLatest(EXPORT_ALL_IMAGES, exportAllImagesSaga);
  // export report included images
  yield takeLatest(EXPORT_REPORT_INCLUDED_IMAGES, exportReportIncludedImagesSaga);
  // poll export images
  yield takeLatest(POLL_EXPORT_IMAGES, pollExportImagesSaga);
  // download exported images
  yield takeLatest(DOWNLOAD_EXPORTED_IMAGES, downloadExportedImagesSaga);
  // test square location update
  yield takeLatest(TEST_SQUARE_LOCATION_UPDATE, updateTestSquareLocationSaga);
  // App settings
  yield takeLatest(FETCH_ASSESS_APP_SETTINGS, fetchAssessAppSettingsSaga);
  // fetch report versions
  yield takeLatest(FETCH_REPORT_VERSIONS, fetchReportVersionsSaga);
  // save rotation for gallery Images
  yield takeLatest(SAVE_ROTATION_FOR_GALLERY_IMAGES, saveRotationforGalleryImagesSaga);
  // fetch feature preferences to be opt out
  yield takeLatest(FETCH_FEATURE_PREFERENCE, fetchFeaturePreferenceSaga);
  // save confidence factor for the order
  yield takeLatest(SAVE_CONFIDENCE_FACTOR, saveConfidenceFactorSaga);
  // Save annotation for facet
  yield takeLatest(SAVE_FACET_ANNOTATION, saveAnnotationForFacetSaga);
  // update user defined anomaly location
  yield takeLatest(UPDATE_USER_DEFINED_ANOMALY_LOCATION, updateUserDefinedAnomalyLocationSaga);
  // Display annotation for facet
  yield takeLatest(DISPLAY_FACET_ANNOTATIONS, displayFacetAnnotationSaga);
  // delete facet annotation
  yield takeLatest(DELETE_FACET_ANNOTATION, deleteFacetAnnotationSaga);
  // Update annotation for facet
  yield takeLatest(UPDATE_FACET_ANNOTATION, updateFacetAnnotationSaga);
  yield takeLatest(UPDATE_FACET_ANNOTATION_INCL_IN_REPORT, updateFacetAnnotationInclInReportSaga);
  // fetch master accessory list
  yield takeLatest(FETCH_ACCESSORIES_MASTER_LIST, fetchAccessoryMasterListSaga);
  // fetch accessory summary
  yield takeLatest(FETCH_ACCESSORIES_SUMMARY, fetchAccessorySummarySaga);
  // Multi Structure
  yield takeLatest(UPDATE_STRUCTURE_VISIT_STATUS, updateStructureVisitStatusSaga);
  yield takeLatest(FETCH_MULTISTRUCTURE_DECISION, fetchMultiStructureDecisionSaga);
  yield takeLatest(FETCH_ANOMALY_IMAGES, fetchAnomalyImagesSaga);
  yield takeLatest(FETCH_REPORT_INCLUDED_SOFT_METAL_IMAGES, fetchSoftMetalImagesIncludedInReportSaga);
  yield takeEvery(SAVE_SOFT_METAL_IMAGE_REPORT_INCLUSION, saveSoftMetalImageReportInclusionSaga);
  yield takeEvery(DELETE_SOFT_METAL_IMAGE_REPORT_INCLUSION, deleteSoftMetalImageReportInclusionSaga);
  yield takeLatest(FETCH_REPORT_INCLUDED_SKYDIO_IMAGES, fetchSkydioImagesIncludedInReportSaga);
  yield takeEvery(SAVE_SKYDIO_IMAGE_REPORT_INCLUSION, saveSkydioImageReportInclusionSaga);
  yield takeEvery(DELETE_SKYDIO_IMAGE_REPORT_INCLUSION, deleteSkydioImageReportInclusionSaga);
  yield takeLatest(GET_ANOMALY_NOTES, getAnomalyNotesSaga);
  yield takeLatest(ADD_ANOMALY_NOTE, addAnomalyNoteSaga);
  yield takeLatest(UPDATE_ANOMALY_NOTE, updateAnomalyNoteSaga);
  yield takeLatest(INCLUDE_ANOMALY_NOTE, includeAnomalyNoteSaga);
  yield takeLatest(FETCH_FACET_IMAGE_ANOMALIES, fetchFacetImageAnomaliesSaga);
  yield takeLatest(FETCH_SHARED_USERS_LIST, fetchSharedUsersListSaga);
  yield takeLatest(CREATE_SHARE_LINK, createShareLinkSaga);
  yield takeLatest(REPORT_ATTEMPTS, getReportAttempts);
}
