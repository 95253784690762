import React, { useState, useEffect } from 'react';
import {
  EVTabs,
  EVTab,
  EVSidePanel,
  EVBox,
  EVTypography,
  EVLandscapeIcon,
  EVImagePreviewer,
  EVChip,
} from '@eagleview/ev-comp-library';
import { func, shape, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import * as action from 'layout/adjuster/Adjuster.actions';
import { ASSESS_IMAGES_API_ENDPOINT } from 'constants.js';
import toUpper from 'lodash/toUpper';
import clsx from 'clsx';
import * as sharedUtil from 'utils/utils';
import EntitledComponent from 'components/EntitledComponent';
import ErrorBoundary from 'components/ErrorBoundary';
import { FEATURE_ENTITLEMENTS, ASSESS_MARKETING_PRODUCTNAMES } from 'layout/entitleUser/EntitleUser.constants';
import { isEmpty } from 'lodash';
import useStyles from './AdjusterSidePanel.styles';
import SidePanelRoof from './sidepanel-roof/SidePanelRoof';
import SidePanelDetails from './sidepanel-details/SidePanelDetails';
import SidePanelGallery from './sidepanel-gallery/SidePanelGallery';
import {
  statusToTranslation, TABS, FETCH_RISK_SHOT, FETCH_FACET_BASE_IMAGE,
} from '../Adjuster.constants';
import { claimStatus, MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME } from '../../homeLayout/home/Home.constants';

/**
 * Represents adjuster side panel
 */
const AdjusterSidePanel = ({ setResize, setFacetWithInitialAnomaly, showTabs }) => {
  // utility hooks
  const styles = useStyles();
  const { t } = useTranslation();

  // actions
  const dispatch = useDispatch();
  const setSidePanelWidth = (payload) => dispatch(action.setSidePanelWidthAction(payload));
  const setCurrentTabAction = (payload) => dispatch(action.setCurrentTabAction(payload));

  // selectors
  const selectedStructureId = useSelector((state) => state.adjusterReducer.selectedStructureId);
  const riskShots = useSelector((state) => get(state.adjusterReducer, 'riskShot', false));
  const claimId = useSelector((state) => state.adjusterReducer.claimId);
  const addressCity = useSelector((state) => state.adjusterReducer.addressCity);
  const addressStreet = useSelector((state) => state.adjusterReducer.addressStreet);
  const currentTab = useSelector((state) => state.adjusterReducer.currentTab);
  const galleryImages = useSelector((state) => state.adjusterReducer.galleryImages);
  const orderStatus = useSelector((state) => state.adjusterReducer.orderStatus);
  const { structures = [] } = useSelector((state) => state.adjusterReducer.multiStructure);
  const isAssessLite = useSelector((state) => state.adjusterReducer.isAssessLite);
  const flightType = useSelector((state) => state.adjusterReducer.flightType);
  const enableMarketingProductNames = useSelector((state) => get(state.entitleUserReducer.featureFlags, ASSESS_MARKETING_PRODUCTNAMES, false));
  const baseImage = useSelector((state) => get(state.adjusterReducer, 'baseImage', ''));
  const loading = useSelector((state) => state.adjusterReducer.loading);
  const authToken = useSelector((state) => state.adjusterReducer.accessToken);

  // derived values
  const riskShot = get(riskShots, `${selectedStructureId}.images[0].imageURN`, false);
  const disableFocusOnGalleryTab = clsx(styles.tabBar, currentTab === TABS.GALLERY && styles.tabBarNoFocus);
  const selectRiskShotImage = galleryImages.filter((image) => image.urn === riskShot);
  const riskShotOrientation = get(selectRiskShotImage, [0, 'orientation'], 0);
  const baseImageOrientation = get(baseImage, 'customMeta.orientation', 0);
  const colorByStatus = {
    ReadyForReview: styles.inReviewChip,
    Completed: styles.completedChip,
    OrderClosed: styles.completedChip,
  };
  const showStatus = [claimStatus.READYFORREVIEW, claimStatus.COMPLETED, claimStatus.ORDERCLOSED].includes(orderStatus);
  const selectedStructure = structures.find((structure) => structure.structureID === selectedStructureId);
  const isCaptured = get(selectedStructure, 'isCaptured');
  const baseImageURN = get(baseImage, 'image_urn', '');

  const setCurrentTab = (e, id) => {
    setCurrentTabAction(id);
  };
  const composeRiskShotURL = (authtoken) => `${ASSESS_IMAGES_API_ENDPOINT}/${riskShot || baseImageURN}/thumb?width=362&height=240&`
  + `${sharedUtil.getAuthParam(authtoken)}`;

  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  const riskShotORBaseImageAvailable = (riskShot || baseImageURN) && !(loading[FETCH_RISK_SHOT] || loading[FETCH_FACET_BASE_IMAGE]);

  useEffect(() => {
    if (riskShotORBaseImageAvailable) {
      const newUrl = composeRiskShotURL(authToken);
      setImagePreviewUrl(newUrl);
    }
  }, [authToken, riskShot, baseImageURN]);

  const composeInitialRotation = () => (360 - (riskShot ? riskShotOrientation : baseImageOrientation));
  const onWidthChange = (val) => {
    setSidePanelWidth(val);
    setTimeout(() => {
      setResize();
    }, 0);
  };

  const orderTitle = () => {
    if (enableMarketingProductNames) {
      return MAP_CLAIM_TITLE_WITH_MARKETING_PRODUCT_NAME[flightType];
    }
    return isAssessLite ? 'claim.flightType.quickCapture' : '';
  };

  return (
    <EVSidePanel
      visibilityToggleEnabled
      resizeHandleEnabled={false}
      option={currentTab}
      visibility="show"
      width="370px"
      elevation={6}
      height="100%"
      className={styles.sidepanel}
      onWidthChange={onWidthChange}
      toggleButtonClass={styles.collapseButton}
    >
      <EVBox className={styles.riskShowWrapper}>
        {riskShotORBaseImageAvailable && !isEmpty(imagePreviewUrl) ? (
          <div
            className={styles.riskShotStyle}
            data-testid="riskShotImage"
          >
            <EVImagePreviewer
              url={imagePreviewUrl}
              initialRotation={composeInitialRotation()}
              detectWheel={false}
              disableDoubleClickZoomWithToolAuto
              tool="none"
              disableFitToWindow
              disableFullSize
              disableRotate
              disableZoomIn
              disableZoomOut
            />
          </div>
        ) : (
          <EVBox
            data-testid="riskShotPlaceholder"
            width="100%"
            height="240px"
            bgcolor="grey.300"
            color="common.white"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <EVLandscapeIcon style={{ fontSize: 100 }} />
          </EVBox>
        )}
      </EVBox>
      <EVBox className={styles.claimDetails}>
        <EVBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className={styles.claimDetailsHeader}
        >
          <EVTypography variant="h4" className={styles.claimId}>
            {claimId}
          </EVTypography>
          <EVBox display="flex" justifyContent="flex-end" alignItems="center">
            {!isEmpty(orderTitle()) && (
              <EVChip
                size="small"
                className={clsx(styles.captureType, styles.chipStyle)}
                label={toUpper(t(orderTitle()))}
              />
            )}
            {showStatus && (
              <EntitledComponent entitlement={FEATURE_ENTITLEMENTS.VIEW_DECISIONS}>
                <EVChip
                  className={clsx(colorByStatus[orderStatus], styles.chipStyle)}
                  label={toUpper(t(statusToTranslation[orderStatus]))}
                />
              </EntitledComponent>
            )}
          </EVBox>
        </EVBox>
        <EVTypography variant="h3" gutterBottom className={styles.addressStreetStyle}>
          {addressStreet}
        </EVTypography>
        <EVTypography variant="h4">{addressCity}</EVTypography>
      </EVBox>
      {isAssessLite !== null && (
        <>
          <EVTabs
            value={currentTab}
            onChange={setCurrentTab}
            textColor="primary"
            indicatorColor="primary"
            className={styles.tabs}
          >
            {showTabs.details
              && (<EVTab className={disableFocusOnGalleryTab} value={isAssessLite ? TABS.DETAILS_LITE : TABS.DETAILS} label={t('adjuster.details')} />)}
            {showTabs.roof
              && (<EVTab className={disableFocusOnGalleryTab} value={isAssessLite ? TABS.ROOF_LITE : TABS.ROOF} label={t('adjuster.roof')} />)}
            {showTabs.elevation
              && (<EVTab className={disableFocusOnGalleryTab} value={TABS.GALLERY} label={t('adjuster.gallery')} data-testid="galleryButton" />)}
            {showTabs.gallery
              && (
                <EVTab
                  className={disableFocusOnGalleryTab}
                  value={TABS.PHOTOS_GALLERY}
                  label={t('photosGallery.gallery')}
                  data-testid="galleryButton"
                />
              )}
          </EVTabs>
          {(currentTab === TABS.DETAILS || currentTab === TABS.DETAILS_LITE) && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelDetails"
              alertMsg={t('sidePanel.sidePanelDetailsCrash')}
            >
              <SidePanelDetails />
            </ErrorBoundary>
          )}
          {currentTab === TABS.ROOF && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelRoof"
              alertMsg={t('sidePanel.sidePanelRoofCrash')}
            >
              <SidePanelRoof setFacetWithInitialAnomaly={setFacetWithInitialAnomaly} isCaptured={isCaptured} />
            </ErrorBoundary>
          )}
          {(currentTab === TABS.GALLERY || currentTab === TABS.ROOF_LITE) && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelGallery"
              alertMsg={t('sidePanel.sidePanelGalleryCrash')}
            >
              <SidePanelGallery />
            </ErrorBoundary>
          )}
          {currentTab === TABS.PHOTOS_GALLERY && (
            <ErrorBoundary
              componentName="SidePanel: SidePanelGallery"
            >
              Photos Gallery
            </ErrorBoundary>
          )}
        </>
      )}
    </EVSidePanel>
  );
};

AdjusterSidePanel.propTypes = {
  setResize: func,
  setFacetWithInitialAnomaly: func,
  showTabs: shape({
    details: bool,
    roof: bool,
    elevation: bool,
    gallery: bool,
  }),
};

AdjusterSidePanel.defaultProps = {
  setResize: () => { // Empty block
  },
  setFacetWithInitialAnomaly: () => { // Empty block
  },
  showTabs: {
    details: true,
    roof: true,
    elevation: true,
    gallery: false,
  },
};

export default AdjusterSidePanel;
